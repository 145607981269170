<template>
  <div class="examinerManagement">
    <div class="flex-box">
      <div class="left">
        <el-button class="marginRight"
                   type="success"
                   @click="addPositionTime">添加数据</el-button>
        <el-upload action="/api/position/hour/importPositionData"
                   :data="{ schoolYearId: schoolYearId }"
                   class="display marginRight"
                   :headers="uploadHeader"
                   name="file"
                   :on-success="uploadSuccess"
                   :show-file-list="false">
          <el-button type="primary"
                     icon="el-icon-upload2">导 入 </el-button>
        </el-upload>
        <el-button class="marginRight"
                   type="primary"
                   icon="el-icon-document"
                   @click="DownloadTemplate(1)">下载导入模板</el-button>
        <el-button class="marginRight"
                   type="primary"
                   @click="DownloadTemplate(2)">导出数据</el-button>
        <el-button class="marginRight"
                   type="danger"
                   icon="el-icon-document"
                   @click="batchDelete">批量删除</el-button>

      </div>
    </div>
    <el-table :data="positionTimeList"
              @selection-change="selectChange">
      <el-table-column type="selection"
                       key="index2"
                       align="center"></el-table-column>
      <el-table-column label="序号"
                       width="60"
                       type="index"
                       align="center"></el-table-column>
      <el-table-column label="教师姓名"
                       prop="managerName"
                       align="center"></el-table-column>
      <el-table-column label="周课时（小时）"
                       prop="hour"
                       align="center"></el-table-column>
      <el-table-column label="职位"
                       prop="job"
                       align="center"></el-table-column>
      <el-table-column label="操作"
                       width="180px"
                       prop="course"
                       align="center">
        <template slot-scope="scope">
          <el-button type="primary"
                     @click="editPositionTime(scope.row)">修改
          </el-button>
          <el-button type="danger"
                     @click="deletePositionTime(scope.row.id)">删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination layout="prev,pager,next"
                   background
                   :page-count="page.pageCount"
                   :page-size="page.currentPage"
                   @current-change="loadPositionTime"></el-pagination>

    <el-dialog :title="addPositionTimeForm.id !== 0 ? '编辑职位课时' : '添加职位课时'"
               :visible.sync="addPositionTimeDialogVisible"
               width="600px">
      <div class="center">
        <el-form :model="addPositionTimeForm"
                 ref="addPositionTimeFormRef"
                 label-width="100px"
                 :rules="addPositionTimeFormRules">
          <el-form-item label="教师："
                        prop="managerId">
            <el-select filterable
                       @change="teacherSelectChange"
                       v-model="addPositionTimeForm.managerId"
                       clearable
                       placeholder="请选择教师">
              <el-option v-for="obj in teacherSelectList"
                         :key="obj.id"
                         :label="obj.name"
                         :value="obj.id" />
            </el-select>
          </el-form-item>
          <el-form-item label="周课时："
                        prop="hour">
            <el-input v-model="addPositionTimeForm.hour"
                      placeholder="请输入周课时（小时）"></el-input>
          </el-form-item>

          <el-form-item label="职务：">
            <el-input v-model="position"
                      disabled
                      placeholder="选择教师后自动带出"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer"
            class="dialog-footer">
        <el-button @click="addPositionTimeDialogVisible = false">取 消</el-button>
        <el-button type="primary"
                   @click="addPositionTimeConfirm">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "templateCourseTime",
  data() {
    return {
      page: { pageCount: 1, currentPage: 0 },
      teachersList: [],
      // 表单
      addPositionTimeForm: {
        id: 0,
        schoolYearId: "",
        managerId: "",
        managerName: "",
        hour: "",
        positionName: "",
      },
      positionId: "",
      // 验证规则
      addPositionTimeFormRules: {
        id: [{ required: true }],
        schoolYearId: [{ required: true }],
        managerId: [{ required: true }],
        managerName: [{ required: true }],
        hour: [{ required: true }],
        positionName: [{ required: true }],
      },
      // 添加职位课时对话框显示与隐藏
      addPositionTimeDialogVisible: false,
      // 学期id
      schoolYearId: Number(this.$route.query.termid),
      // 职位课时列表
      positionTimeList: [],
      // 教师下拉选择列表
      teacherSelectList: [],
      uploadHeader: { Authorization: sessionStorage.getItem("token") },
      // 批量删除列表
      batchDeleteList: [],
      position: "",
    };
  },
  created() {
    this.loadPositionTime(1);
  },
  methods: {
    // 职位选中时
    selectChange(list) {
      this.batchDeleteList = list.map((item) => item.id);
    },
    // 职位批量删除
    batchDelete() {
      this.$confirm("是否批量删除这些数据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$postJson(`/position/hour/deleteByIds`, {
            ids: this.batchDeleteList,
          })
            .then((res) => {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              this.loadPositionTime(this.page.currentPage);
            })
            .catch((err) => {
              this.$message.error(err.message);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // // 获取初始页面数据
    loadPositionTime(page) {
      const that = this;
      let data = {
        indexNo: page,
        rows: 10,
        schoolYearId: this.schoolYearId,
      };
      this.$postJson("/position/hour/queryList", data)
        .then((res) => {
          that.page.currentPage = res.data.indexNO;
          that.page.pageCount = res.data.pageCount;
          // res.data.rows.forEach(item => {
          //     item.hour = item.hour + '小时'
          // })
          that.positionTimeList = res.data.rows;
          console.log(that.positionTimeList);
        })
        .catch((err) => {
          that.$message({
            type: "warning",
            message: err.message,
          });
        });
    },
    // 教师查询
    queryTeacher() {
      this.$post("/media/queryAllManager.do").then((res) => {
        console.log(res);
        this.teacherSelectList = res.data;
      });
    },
    // 教师选择变化时触发
    teacherSelectChange(value) {
      this.teacherSelectList.forEach((item) => {
        if (item.id === value) {
          this.addPositionTimeForm.managerName = item.name;
        }
      });
      this.queryPosition(value);
    },
    // 查询教师职位
    queryPosition(value) {
      this.$post(`media/queryJobByBook.do`, { teacherId: value }).then(
        (res) => {
          // console.log(res)
          this.position = res.data;
        }
      );
    },
    // 添加职位课时
    addPositionTime() {
      // 教师查询
      this.queryTeacher();
      this.addPositionTimeForm.id = 0;
      this.addPositionTimeForm.schoolYearId = this.schoolYearId;
      this.addPositionTimeDialogVisible = true;
      this.$nextTick(() => {
        this.$refs.addPositionTimeFormRef.resetFields();
      });
    },
    // 编辑
    editPositionTime(row) {
      this.queryTeacher();
      this.addPositionTimeDialogVisible = true;
      this.$nextTick(() => {
        this.addPositionTimeForm.id = row.id;
        this.addPositionTimeForm.schoolYearId = row.schoolYearId;
        this.addPositionTimeForm.managerId = row.managerId;
        this.addPositionTimeForm.managerName = row.managerName;
        this.addPositionTimeForm.hour = row.hour;
        this.addPositionTimeForm.positionName = row.positionName;
        this.position = row.job;
      });
    },
    // 表单提交
    addPositionTimeConfirm() {
      console.log(this.addPositionTimeForm);
      this.$refs.addPositionTimeFormRef.validate((validate) => {
        if (validate) {
          // 编辑职位课时表单提交
          if (this.addPositionTimeForm.id !== 0) {
            console.log(1);
            this.$postJson(
              "/position/hour/savePositionHour",
              this.addPositionTimeForm
            ).then((res) => {
              this.$message.success("编辑成功");
              this.addPositionTimeDialogVisible = false;
              // 更新当前页数据列表
              this.loadPositionTime(this.page.currentPage);
            });
            return;
          }
          // 添加职位课时表单提交
          const data = {
            id: this.addPositionTimeForm.id,
            schoolYearId: this.addPositionTimeForm.schoolYearId,
            managerId: this.addPositionTimeForm.managerId,
            managerName: this.addPositionTimeForm.managerName,
            hour: this.addPositionTimeForm.hour,
            positionName: this.addPositionTimeForm.positionName,
          };
          console.log(data);
          this.$postJson("/position/hour/savePositionHour", data)
            .then((res) => {
              this.$message.success("添加成功");
              this.addPositionTimeDialogVisible = false;
              this.loadPositionTime(1);
            })
            .catch((err) => {
              console.log(1);
              this.$message.error(err.message.data);
            });
        }
      });
    },
    // 删除
    deletePositionTime(id) {
      const that = this;
      this.$confirm("是否移除此条记录?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$post(`/position/hour/deletePositionHour/${id}`)
            .then((res) => {
              that.$message({
                type: "success",
                message: "删除成功!",
              });
              that.loadPositionTime(this.page.currentPage);
            })
            .catch((err) => {
              that.$message.error(err.message);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 导入
    uploadSuccess(response, file, fileList) {
      if (response.type === 1) {
        this.$message({
          type: "success",
          message: "已上传文件",
        });
        this.loadPositionTime(1);
      } else {
        this.$message({
          type: "warning",
          message: response.message,
        });
      }
    },
    // 下载模板
    DownloadTemplate(type) {
      const that = this;
      let url =
        type === 1
          ? `/position/hour/downloadPositionTemplate`
          : `/position/hour/exportPositionHour.do`;
      let name = type === 1 ? `职位课时导入模板` : `职位课时数据`;
      let data = type === 1 ? {} : { schoolYearId: this.schoolYearId };

      this.$postFile(url, data)
        .then((res) => {
          console.log(res.data);
          const blob = new Blob([res.data], {
            type: "application/vnd.ms-excel;",
          });
          const a = document.createElement("a");
          // 生成文件路径
          let href = window.URL.createObjectURL(blob);
          a.href = href;
          a.download = name;
          // 利用a标签做下载
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(href);
        })
        .catch((error) => {
          that.$message({
            type: "warning",
            message: error.message,
          });
        });
    },
  },
};
</script>

<style scoped>
.input-box {
  width: 180px;
  margin: 0 5px;
}

.flex-box {
  display: flex;
  justify-content: space-between;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.picker {
  margin-left: 10px;
}

.display {
  display: inline-block;
}

.marginRight {
  margin-right: 10px;
}
</style>
